<template>
  <base-index-layout :title="$tc('facility.name', 2)">
    <template #new-button>
      <base-new-button class="mx-4" :to="{ path: 'new' }" append />
    </template>
    <FacilityIndexTable />
  </base-index-layout>
</template>

<script>
import FacilityIndexTable from './components/FacilityIndexTable'

export default {
  components: {
    FacilityIndexTable
  }
}
</script>
