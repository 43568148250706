var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.facilities,"loading":_vm.isLoading,"loader-height":"1","items-per-page":15,"footer-props":{
    itemsPerPageOptions: [10, 25, 50, -1]
  }},scopedSlots:_vm._u([{key:"item.attributes.address",fn:function({ item }){return [_c('div',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"180px"}},[_vm._v(" "+_vm._s(item.attributes.address)+" ")])]}},{key:"item.items",fn:function({ item }){return [_vm._v(" "+_vm._s(item.relationships.items.data.length)+" ")]}},{key:"item.id",fn:function({ item }){return [_c('BaseTableActions',{attrs:{"module-name":"facility","item":item,"to-show":{
        name: 'facility-edit',
        params: { facilityId: item.id }
      },"to-edit":{
        name: 'facility-edit',
        params: {
          facilityId: item.id
        }
      }}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }