<template>
  <v-data-table
    :headers="headers"
    :items="facilities"
    :loading="isLoading"
    loader-height="1"
    :items-per-page="15"
    :footer-props="{
      itemsPerPageOptions: [10, 25, 50, -1]
    }"
  >
    <template v-slot:item.attributes.address="{ item }">
      <div class="d-inline-block text-truncate" style="max-width: 180px;">
        {{ item.attributes.address }}
      </div>
    </template>

    <template v-slot:item.items="{ item }">
      {{ item.relationships.items.data.length }}
    </template>

    <template v-slot:item.id="{ item }">
      <BaseTableActions
        module-name="facility"
        :item="item"
        :to-show="{
          name: 'facility-edit',
          params: { facilityId: item.id }
        }"
        :to-edit="{
          name: 'facility-edit',
          params: {
            facilityId: item.id
          }
        }"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import BaseTableActions from '@/components/buttons/BaseTableActions'
export default {
  name: 'FacilityIndexTable',
  components: {
    BaseTableActions
  },
  data() {
    return {
      userGroups: [],
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      facilities: 'facility/facilities'
    }),
    headers() {
      return this.createTableHeaders()
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      this.isLoading = true
      await this.filterFacilities()
      this.isLoading = false
    },
    async filterFacilities() {
      await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID(),
        category: 'Warehouse'
      })
    },
    removeRow(facility) {
      const answer = window.confirm(
        'Do you really want to delete this Warehouse? This information will be erased from your data'
      )
      if (!answer) return
      this.$store.dispatch('facility/delete', facility)
    },
    createTableHeaders() {
      return [
        {
          text: this.$tc('facility.name', 1) + '#',
          align: 'start',
          sortable: false,
          value: 'attributes.name'
        },
        {
          text: this.$tc('item.name', 2),
          value: 'items',
          sortable: false
        },
        {
          text: this.$t('common.contactPerson'),
          value: 'attributes.contactPerson',
          sortable: false
        },
        {
          text: this.$t('common.address'),
          sortable: false,
          value: 'attributes.address'
        },
        {
          text: this.$t('common.phone'),
          value: 'attributes.phone'
        },
        {
          text: this.$t('common.email'),
          value: 'attributes.email'
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped></style>
